import { createSelector } from '@ngrx/store';
import { selectWidgetsState } from './feature';

export const selectWidgetGroup = (groupId: string) =>
  createSelector(
    selectWidgetsState,
    (s) => s.groups.find((g) => g.id === groupId) ?? null
  );

export const selectActiveGroupWidgets = (groupId: string) => {
  const groupSelector = selectWidgetGroup(groupId);
  return createSelector(groupSelector, (s) =>
    s?.widgets.filter((w) => !w.shelved)
  );
};

export const selectShelvedGroupWidgets = (groupId: string) => {
  const groupSelector = selectWidgetGroup(groupId);
  return createSelector(groupSelector, (s) =>
    s?.widgets.filter((w) => !!w.shelved)
  );
};

export const selectGroupEditMode = (groupId: string) => {
  const groupSelector = selectWidgetGroup(groupId);
  return createSelector(groupSelector, (s) => s?.isEditorEnabled);
};

export const selectLastInitializedWidgetGroupId = createSelector(
  selectWidgetsState,
  (s) => s.lastInitialedGroupId
);
