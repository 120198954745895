<div class="knk-widget-card" *ngIf="dropdownButtons?.length && dropdownButtons.length > 0">
  <knk-dropdown-menu>
    <ng-template
      *ngFor="let action of dropdownButtons"
      knkDropdownMenuItem
      (activated)="action.action()"
      [label]="action.label"
    ></ng-template>
  </knk-dropdown-menu>
</div>

<div class="knk-widget-card__widgets">
  <knk-widget-grid *ngIf="widgetGroup" [group]="widgetGroup"></knk-widget-grid>
</div>
