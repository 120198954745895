import {
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewRef
} from '@angular/core';

@Component({
  selector: 'knk-widget',
  template: ` <ng-container #container></ng-container> `
})
export class WidgetComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  viewContainerRef!: ViewContainerRef;

  @Input() createdWidgetRef!: ComponentRef<unknown>;

  private _viewRef: ViewRef | null = null;

  ngOnInit(): void {
    this._viewRef = this.viewContainerRef.insert(
      this.createdWidgetRef.hostView
    );
  }

  ngOnDestroy(): void {
    this._viewRef?.destroy();
  }
}
