import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownMenuModule } from '@konnektu/components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GridsterModule } from 'angular-gridster2';
import { WidgetCardComponent } from './components/widget-card/widget-card.component';
import { WidgetGridComponent } from './components/widget-grid/widget-grid.component';
import { WidgetComponent } from './components/widget.component';
import { WidgetEffects } from './state/effects';
import { widgetFeature } from './state/feature';

/**
 * @todo we need to remove gridster from project
 * @deprecated
 */
@NgModule({
  declarations: [WidgetCardComponent, WidgetGridComponent, WidgetComponent],
  imports: [
    CommonModule,
    GridsterModule,
    StoreModule.forFeature(widgetFeature),
    DropdownMenuModule,
    EffectsModule.forFeature([WidgetEffects])
  ],
  exports: [WidgetCardComponent, WidgetGridComponent]
})
export class WidgetModule {}
