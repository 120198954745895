import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TrackByFunction,
  Type,
  ViewChild,
  inject
} from '@angular/core';
import {
  DestroyService,
  createContextLogger,
  isDefined
} from '@konnektu/helpers';
import { SidebarService } from '@konnektu/sidebar-layout';
import { Store } from '@ngrx/store';
import {
  GridsterComponent,
  GridsterConfig,
  GridsterConfigService,
  GridsterItem
} from 'angular-gridster2';
import { Observable, filter, map, take, takeUntil, tap } from 'rxjs';
import { WIDGET_GROUP_CONFIG } from '../../models';
import {
  disableWidgetEditor,
  initializeWidgetGroup,
  shelfWidget,
  unshelfWidget,
  updateWidget
} from '../../state/actions';
import { WidgetGroupState } from '../../state/feature';
import {
  selectActiveGroupWidgets,
  selectGroupEditMode,
  selectLastInitializedWidgetGroupId,
  selectWidgetGroup
} from '../../state/selectors';

export interface ExtendedGridsterItem extends GridsterItem {
  knkComponentName: string;
}

@Component({
  selector: 'knk-widget-grid',
  templateUrl: 'widget-grid.component.html',
  styleUrls: ['widget-grid.component.scss'],
  providers: [DestroyService]
})
export class WidgetGridComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly store = inject(Store);

  private readonly injector = inject(Injector);

  private readonly resolver = inject(ComponentFactoryResolver);

  private readonly destroy$ = inject(DestroyService);

  private readonly cd = inject(ChangeDetectorRef);

  private readonly config = inject(WIDGET_GROUP_CONFIG);

  private readonly sidenavService = inject(SidebarService);

  @Input() group!: string;

  @ViewChild('gridster', { static: true })
  gridsterComponent!: GridsterComponent;

  private readonly logger = createContextLogger('WidgetGridComponent');

  widgetGroupSelector: ReturnType<typeof selectWidgetGroup> | null = null;

  widgetGroup$: Observable<WidgetGroupState> | null = null;

  groupWidgetsStateSelector: ReturnType<
    typeof selectActiveGroupWidgets
  > | null = null;

  groupWidgets$: Observable<
    {
      widgetComponent: ComponentRef<unknown>;
      item: GridsterItem;
    }[]
  > | null = null;

  editMode$: Observable<boolean> | null = null;

  gridOptions: GridsterConfig = {
    ...GridsterConfigService,
    minCols: 10,
    minRows: 10,
    disableWarnings: false,
    disableScrollVertical: true,
    disableScrollHorizontal: true,
    enableEmptyCellDrop: true,
    resizable: {
      enabled: false
    },
    draggable: {
      enabled: false,
      ignoreContent: true,
      dragHandleClass: 'move-handle'
    },
    emptyCellDropCallback: (event) => {
      if (event.dataTransfer?.getData('componentTypeName')) {
        this.store.dispatch(
          unshelfWidget({
            groupId: this.group,
            componentType: event.dataTransfer?.getData('componentTypeName')
          })
        );
      }
    },
    itemChangeCallback: (item) => {
      this.store.dispatch(
        updateWidget({
          groupId: this.group,
          componentTypeName: (item as ExtendedGridsterItem).knkComponentName,
          x: item.x,
          y: item.y,
          cols: item.cols,
          rows: item.rows
        })
      );
    }
  };

  trackBy: TrackByFunction<{
    widgetComponent: ComponentRef<unknown>;
    item: GridsterItem;
  }> = (_, item) => (item.item as ExtendedGridsterItem).knkComponentName;

  constructor() {}

  ngOnInit(): void {
    this.config.pipe(takeUntil(this.destroy$)).subscribe((config) => {
      if (!config || config.groupId !== this.group) {
        throw new Error(`Group with name: ${this.group} is not provided`);
      }
      this.logger.debug(`Initializing widget-grid for group: ${this.group}`);
      this.store.dispatch(
        initializeWidgetGroup({ group: config.groupId, config })
      );

      this.groupWidgetsStateSelector = selectActiveGroupWidgets(this.group);
      this.groupWidgets$ = this.store
        .select(this.groupWidgetsStateSelector)
        .pipe(
          filter(isDefined),
          map((widgets) =>
            widgets.map((widget) => ({
              widgetComponent: this.createWidgetComponent(
                (
                  config.widgets.find(
                    (wc) => wc.componentTypeName === widget.componentTypeName
                  ) as any
                ).componentTypeCtor
              ),
              item: {
                cols: widget.cols,
                rows: widget.rows,
                x: widget.x,
                y: widget.y,
                minItemCols: widget.minCols,
                minItemRows: widget.minRows,
                knkComponentName: widget.componentTypeName
              } as ExtendedGridsterItem
            }))
          ),
          tap((widgets) => {
            this.logger.debug('Reinitialized widgets!', widgets);
            if (this.gridOptions.api?.optionsChanged) {
              this.gridOptions.api.optionsChanged();
            }
            if (this.gridOptions.api?.resize) {
              this.gridOptions.api.resize();
            }
          })
        );

      this.store
        .select(selectGroupEditMode(this.group))
        .pipe(
          takeUntil(this.destroy$),
          tap((editor) =>
            this.logger.debug(
              `Editor ${editor ? 'enabled' : 'disabled'}`,
              editor
            )
          )
        )
        .subscribe((editMode) => {
          if (this.gridOptions.draggable) {
            this.gridOptions.draggable.enabled = editMode;
          }
          if (this.gridOptions.resizable) {
            this.gridOptions.resizable.enabled = editMode;
          }
          this.cd.detectChanges();
          if (this.gridOptions.api?.optionsChanged) {
            this.gridOptions.api.optionsChanged();
          }
        });

      this.sidenavService.isSidenavExpanded$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.gridOptions.api?.resize) {
            this.gridOptions.api?.resize();
          }
        });

      this.editMode$ = this.store
        .select(selectGroupEditMode(this.group))
        .pipe(filter(isDefined));
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.gridsterComponent.updateGrid());
  }

  ngOnDestroy(): void {
    this.store
      .select(selectLastInitializedWidgetGroupId)
      .pipe(take(1), filter(isDefined))
      .subscribe((groupId) =>
        this.store.dispatch(
          disableWidgetEditor({ groupId, rollbackChanges: true })
        )
      );
  }

  createWidgetComponent(ctor: Type<unknown>): ComponentRef<unknown> {
    const compFactory = this.resolver.resolveComponentFactory(ctor);
    return compFactory.create(this.injector);
  }

  shelfWidget(componentType: string): void {
    this.store.dispatch(
      shelfWidget({ groupId: this.group, componentType: componentType })
    );
  }
}
