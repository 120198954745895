import { Type } from '@angular/core';
import { createFeature, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import {
  disableWidgetEditor,
  enableWidgetEditor,
  initializeWidgetGroup,
  shelfWidget,
  toggleWidgetEditor,
  unshelfWidget,
  updateWidget
} from './actions';

export interface WidgetState {
  shelved: boolean;
  readonly componentTypeName: string;
  label: string;
  cols: number;
  rows: number;
  x: number;
  y: number;
  minCols: number;
  minRows: number;
}

export interface WidgetGroupState {
  id: string;
  isEditorEnabled: boolean;
  widgets: WidgetState[];
  widgetsBackup: WidgetState[];
}

export interface WidgetsState {
  lastInitialedGroupId: string | null;
  groups: WidgetGroupState[];
}

export interface WidgetConfig extends Partial<WidgetState> {
  useComponent: Type<unknown>;
  minCols: number;
  minRows: number;
}

export const widgetFeature = createFeature({
  name: 'widgets',
  reducer: createReducer<WidgetsState>(
    {
      lastInitialedGroupId: null,
      groups: []
    },
    on(initializeWidgetGroup, (state, { group, config, force = false }) => {
      const groupInState = state.groups?.find((g) => g.id === group);
      if (!groupInState || force) {
        const widgetsForState = config.widgets.map(
          (wc) =>
            ({
              cols: wc.cols,
              minCols: wc.minCols,
              componentTypeName: wc.componentTypeName,
              label: wc.label,
              rows: wc.rows,
              minRows: wc.minRows,
              shelved: !!wc.shelved,
              x: wc.x,
              y: wc.y
            }) as WidgetState
        );
        return {
          ...state,
          lastInitialedGroupId: group,
          groups: [
            ...state.groups,
            {
              id: group,
              isEditorEnabled: false,
              widgets: widgetsForState,
              widgetsBackup: widgetsForState
            }
          ]
        };
      }
      return { ...state, lastInitialedGroupId: group };
    }),
    on(shelfWidget, (state, { groupId, componentType }) => {
      const groups = cloneDeep(state.groups);
      const widgets = groups.find((g) => g.id === groupId)?.widgets;
      if (!widgets) {
        return state;
      }
      const widget = widgets.find((w) => w.componentTypeName === componentType);
      if (!widget) {
        return state;
      }
      widget.shelved = true;
      return {
        ...state,
        groups
      };
    }),
    on(unshelfWidget, (state, { groupId, componentType }) => {
      const groups = cloneDeep(state.groups);
      const widgets = groups.find((g) => g.id === groupId)?.widgets;
      if (!widgets) {
        return state;
      }
      const widget = widgets.find((w) => w.componentTypeName === componentType);
      if (!widget) {
        return state;
      }
      widget.shelved = false;
      return {
        ...state,
        groups
      };
    }),
    on(enableWidgetEditor, (state, { groupId }) => {
      const group = state.groups.find((g) => g.id === groupId);
      if (group) {
        const groupsCopied = cloneDeep(state.groups);
        const currentGroup = groupsCopied.find((gc) => gc.id === groupId);
        if (!currentGroup) {
          return state;
        }
        currentGroup.isEditorEnabled = true;
        return {
          ...state,
          groups: groupsCopied
        };
      }
      return state;
    }),
    on(disableWidgetEditor, (state, { groupId, rollbackChanges }) => {
      if (rollbackChanges) {
        const copiedGroups = cloneDeep(state.groups);
        const group = copiedGroups.find((g) => g.id === groupId);
        if (!group) return state;
        group.isEditorEnabled = false;
        group.widgets = cloneDeep(group.widgetsBackup);
        return {
          ...state,
          groups: copiedGroups
        };
      }
      const groupsCopied = cloneDeep(state.groups);
      const currentGroup = groupsCopied.find((g) => g.id === groupId);
      if (!currentGroup) {
        return state;
      }
      currentGroup.isEditorEnabled = false;
      return {
        ...state,
        groups: groupsCopied
      };
    }),
    on(toggleWidgetEditor, (state, { groupId }) => {
      const groupsCopied = cloneDeep(state.groups);
      const group = groupsCopied.find((g) => g.id === groupId);
      if (!group) {
        return state;
      }
      group.isEditorEnabled = !group.isEditorEnabled;
      return {
        ...state,
        groups: groupsCopied
      };
    }),
    on(
      updateWidget,
      (state, { groupId, componentTypeName, cols, rows, x, y }) => {
        const groupsCopy = cloneDeep(state.groups);
        const updatedGroup = groupsCopy.find((g) => g.id === groupId);
        if (!updatedGroup) {
          return state;
        }
        const updatedWidget = updatedGroup.widgets.find(
          (w) => w.componentTypeName === componentTypeName
        );
        if (!updatedWidget) {
          return state;
        }
        updatedWidget.cols = cols;
        updatedWidget.rows = rows;
        updatedWidget.x = x;
        updatedWidget.y = y;
        return { ...state, groups: groupsCopy };
      }
    )
  )
});

export const { selectWidgetsState } = widgetFeature;
