import { InjectionToken, Type } from '@angular/core';
import { Observable } from 'rxjs';

export interface WidgetConfig {
  componentTypeName: string;
  componentTypeCtor: Type<unknown>;
  label: string;
  minCols: number;
  minRows: number;
  shelved?: boolean;
  rows?: number;
  cols?: number;
  x?: number;
  y?: number;
}

export interface WidgetGroupConfig {
  groupId: string;
  widgets: WidgetConfig[];
}

export const WIDGET_GROUP_CONFIG = new InjectionToken<
  Observable<WidgetGroupConfig>
>('Widget group config');
