import { Component, Input, OnDestroy } from '@angular/core';
import { SettingService } from '@konnektu/sidebar-layout';

export interface Action {
  label: string;
  action: () => void;
}

@Component({
  selector: 'knk-widget-card',
  templateUrl: 'widget-card.component.html',
  styleUrls: ['widget-card.component.scss']
})
export class WidgetCardComponent implements OnDestroy {
  dropdownButtons: Action[] = [];

  @Input() set actions(actions: Action[]) {
    this.dropdownButtons = actions;
  }

  @Input() widgetGroup!: string;

  private setting: number | null = null;

  constructor(private readonly settings: SettingService) {}

  ngOnDestroy(): void {
    if (this.setting !== null) {
      this.settings.unRegisterSetting(this.setting);
    }
  }
}
