import { createAction, props } from '@ngrx/store';
import { WidgetGroupConfig } from '../models';

export const initializeWidgetGroup = createAction(
  '[Widgets] Initialize group',
  props<{ group: string; config: WidgetGroupConfig; force?: boolean }>()
);
export const initializedWidgetGroup = createAction(
  '[Widgets] Widget group initialized',
  props<{ groupId: string }>()
);
export const shelfWidget = createAction(
  '[Widgets] Shelf widget',
  props<{ groupId: string; componentType: string }>()
);
export const unshelfWidget = createAction(
  '[Widgets] Unshelf widget',
  props<{ groupId: string; componentType: string }>()
);
export const enableWidgetEditor = createAction(
  '[Widgets] Enable editor',
  props<{ groupId: string }>()
);
export const disableWidgetEditor = createAction(
  '[Widgets] Disable editor',
  props<{ groupId: string; rollbackChanges: boolean }>()
);
export const toggleWidgetEditor = createAction(
  '[Widgets] Toggle widget editor',
  props<{ groupId: string }>()
);
export const updateWidget = createAction(
  '[Widgets] Update widget',
  props<{
    groupId: string;
    componentTypeName: string;
    cols: number;
    rows: number;
    x: number;
    y: number;
  }>()
);
