import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, map, take, tap } from 'rxjs';
import { initializedWidgetGroup, initializeWidgetGroup } from './actions';
import { selectWidgetsState } from './feature';

@Injectable()
export class WidgetEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  initWidgetGroup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializeWidgetGroup),
        concatMap(({ group }) =>
          this.store.select(selectWidgetsState).pipe(
            take(1),
            map((state) => ({ state, group }))
          )
        ),
        tap(({ state, group }) => {
          if (state.groups.find((g) => g.id === group)) {
            this.store.dispatch(initializedWidgetGroup({ groupId: group }));
          }
        })
      ),
    { dispatch: false }
  );

  // disableEditorOnNavigation$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(routerNavigatedAction),
  //     switchMap(() => this.store.select(selectLastInitializedWidgetGroupId)),
  //     map((groupId) => disableWidgetEditor({ groupId, rollbackChanges: true }))
  //   )
  // );
}
